<template>
    <div class="live">
      <div class="tilte">
            <div></div>
            <span>{{$t('mine.menuList[1]')}}</span>
        </div>
        <div class="live-List">
          <div class="list" v-for="(item,index) of collectList" :key="index">
            <img class="pic" :src="item.cover" alt="">
            <div class="productDatil">
              <div class="name dis">
                <span>{{isChinese()? item.name:item.nameEng}}</span>
                <i class="el-icon-close" @click="del(item,index)"></i>
              </div>
              <div class="sku">
                <!-- {{isChinese()? item.name:item.nameEng}} -->
              </div>
              <div class="money dis">
                <span>¥{{ item.price }}</span>
                <img src="../../../public/imgs/mine/chat.png"   @click="$router.push({path:'/productDetails',query:{id:item.goodsId}})" alt="">
              </div>
            </div>
          </div>
          <div class="empty" v-if="collectList.length==0">
                <empty :title="isChinese()?'收藏夹为空':'Favorites is empty'"></empty>
          </div>
        </div>
    </div>
  </template>
  
  <script>
import empty from '@/components/empty.vue'
import {listUserCollect,deleteUserCollect} from '@/api/main.js'
export default{
  components:{empty},
    data(){
        return {
          collectList:[]
        }
    },
    mounted(){
      this.listUserCollect()
    },
    methods:{
      listUserCollect(){
        listUserCollect({
        }).then(res=>{
          if(res.code==0){
            this.collectList = res.data
          }else{
            this.showMessage(res)
          }
        })
      },
      del(item,index){
        deleteUserCollect({
          collectId:item.id
        }).then(res=>{
            if(res.code==0){
              this.collectList.splice(index,1)
            }else{
              this.showMessage(res)
            }
        })
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  
  .live{
    width: 880px;
    .tilte{
        display: flex;
        align-items: center;
        padding: 20px 0px;
        div{
            width: 2px;
            height: 24px;
            background: #333333;
            margin-right: 10px;
        }
        span{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }
    .live-List{
      .list{
        padding: 20px 0px;
        border-bottom: 1px solid #ECECEC;
        display: flex;
          justify-content: space-between;
        .pic{
          width: 120px;
          height: 120px;
          background: #ECE8F1;
        }
        .productDatil{
          width: 740px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .dis{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .name{
            span{
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            i{
              font-size: 25px;
            }
          }
          .suk{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .money{
            font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              img{
                width: 40px;
                height: 40px;
              }
          }
        }
      }
    }
  }
  </style>